import React from "react"
// import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { Aside } from "components/blog/aside"
import { Breadcrumb } from "components/breadcrumb"
import { DefaultHeader } from "components/headers/default-header"

const CanIChangeTheOdds = ({ location }) => {
  const title = "如何在即时赢取活动中改变中奖概率"
  const list = [
    { title: "常见问题", link: "/qa" },
    { title: title, link: location.pathname },
  ]

  return (
    <>
      <DefaultHeader lang="zh-CN" />
      <Layout lang="zh-CN">
        <Seo
          pagetitle={`FAQ | ${title}`}
          pagedesc="PARKLoT运动甚至可以在运动中改变获胜的概率。 取胜的概率可以根据运动的情况增加或减少。 奖品可以分配给参与者，没有任何剩余。"
          pagepath={location.pathname}
        />
        <Breadcrumb title="常见问题" items={list} />
        <div className="qa-page l-content l-container">
          <main className="l-main-content">
            <h1 className="qa-page__title">
              是否有可能在竞选期间改变获胜的概率？
            </h1>
            <p className="qa-page__answer">
              是的，获胜的概率可以在竞选期间改变。
              <br />
              然而，在运动过程中，不可能改变现在的情况。
            </p>
            <p className="qa-page__text">
              获奖者是根据概率随机抽出的，所以可能会对获奖者有偏见。
              在这种情况下，可以从管理屏幕上改变概率。
            </p>
            <p className="qa-page__text">
              通过控制所有产品对参与者的分配，确保活动的成功，使礼物没有剩余。
            </p>

            <div className="qa-page__detail-container">
              <div>
                <h2 className="qa-page__h2">如何改变获胜的概率</h2>
                <p className="qa-page__text">
                  在活动管理屏幕上，每种礼品的中奖概率可以设置得不同。
                </p>
              </div>
            </div>
          </main>
          <Aside />
        </div>
      </Layout>
    </>
  )
}

export default CanIChangeTheOdds
